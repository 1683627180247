import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Form } from "react-bootstrap";
import { Button, Checkbox } from 'antd';
import moment from "moment";

import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";
import _db from "../services/data-service";

import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.min.css';

//const SERVICE = "Service"; 
//const PTI_FUEL = "PTI / Fuel";
//const PTI_NOT_DONE = "NOT DONE";
const PTI_STATE_FAILED = "FAILED";
const PTI_STATE_PASSED = "PASSED";

const USER_CLASS_SUPER = "SUPER"; 
const USER_CLASS_ADMIN = "ADMIN";

const monthFormat = 'MMM YYYY';

async function getPutBackItems() {
    let url = "/api/getPutBackItems";
    const { data: response } = await _db.get(url, { headers: authHeader() });
    return response
}

let putBackItems = {};
getPutBackItems().then((items) => {
    for (const { value, description } of items) {
        putBackItems[value] = description;
    }

    //const dictionnaire = items.reduce((acc, { value, description }) => {
    //    acc[value] = description;
    //    return acc;
    //}, {});
});

const Dashboard = () => {
    //const locat = useLocation();
    //const sTerminal = locat.state?.sel;
    //const sLocation = locat.state?.lc;

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let sTerminal = params.get('t');
    let sLocation = params.get('l');
    
    const user = AuthService.getCurrentUser();   
    const [onlineUser, setOnlineUser] = useState({ userCode: '', userName: '', password: '', lines: '', class: '', remote: '', terminal: '', allowPti: false });
    const [terminals, setTerminals] = useState([]);
    const [location, setLocation] = useState(sLocation);
    //const [putBackItems, setPutBackItems] = useState([]); 

    const [isLoading, setIsLoading] = useState(false);
    const [onLoad, setOnLoad] = useState(true);
    const [data, setData] = useState([]);

    //const [container, setContainer] = useState('');
    const userCanApprove = (onlineUser.class === USER_CLASS_ADMIN || onlineUser.class === USER_CLASS_SUPER);

    const [selected, setSelected] = useState(sTerminal);
    const handleChange = e => {
        setSelected(e.target.value);
    }

    async function populateTerminals() {
        const { data: response } = await _db.getTerminals();
        setTerminals(response);
    }

    //const getUserTerminal = async () => {
    //    const { data: response } = await _db.getCurrentUserTerminal();
    //    if (sTerminal) {
    //        setSelected(sTerminal);
    //    } else
    //    if (response !== '') {
    //        setSelected(response);
    //    }
    //}

    const getUserData = async () => {
        const { data: userData } = await _db.getCurrentUserData();
        setOnlineUser({ ...userData });

        if (sTerminal) {
            setSelected(sTerminal);
        } else
            if (userData.terminal !== '') {
                setSelected(userData.terminal);
            }
    }

    //Load on startup
    useEffect(() => {
        populateTerminals();
        //getUserTerminal();
        getUserData();
        //getPutBackItems();
    }, []);

    useEffect(() => {
        if (sLocation) getData();
    }, []);

    const getData = async () => {
        try {
            setIsLoading(true);
            setOnLoad(false);

            let URL = "/api/dashboardGetGensets";
            //console.log('Dashboard Get Genset', authHeader(), selected, location);
            const response = await _db.get(URL, { headers: authHeader(), params: { terminal: selected, location: location } });
            setData(response.data);

            //console.log("data", response.data)
            
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    /* SEARCH */
    const locationChangeHandler = (e) => {
        setLocation(e.target.value.toString().toUpperCase());
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && e.target.value.length > 0) {
            getData()
        }
    }
    /* END SEARCH */

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const handleRowSelect = (e, equip, birthDate, lifeNum, numPhases, fuel, sLine, ptiDone, ptiState) => {
        //console.log(`checked = ${e.target.checked}`);
        const selectedRows = [...selectedRowKeys];
        const index = selectedRows.findIndex(w => w.container === equip);
        const row = {
            "container": equip,
            "birthDate": birthDate,
            "lifeNum": lifeNum,
            "numPhases": numPhases,
            "mecanic": user.userName,
            "fuel": fuel,
            "shippingLine": sLine,
            "terminalCode": selected,
            "ptiDone": ptiDone,
            "ptiState": ptiState
        };

        if (e.target.checked) {         
            if (index < 0) {
                selectedRows.push(row);
            }
        }
        else {
            selectedRows.splice(index, 1);
        }

        setSelectedRowKeys(selectedRows);
    };
    /* END SELECT ROW TO APPROVE */

    const handleApprove = async () => {
        const url = "api/dashboardapprove";
        const oData = {
             "tGInspsJson": JSON.stringify(selectedRowKeys)
        };

        await _db.post(url, oData, { headers: authHeader() })
                 .then((reponse) => {                    
                    getData(); // refresh the page
                    _db.openNotificationWithIcon('success', 'Approved succesfully!');
                 })
                 .catch((error) => {
                    //console.log(error)
                    _db.openNotificationWithIcon('error', error)
                 })
    };

    return (
        <>            
            <div className="border border-5 border-secondary overflow bg-secondary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>

                <div className="container bg-secondary">
                    <div className="row">
                        <div className="col-md-4 my-2">
                            <span className="fs-4 fw-bold text-white">DASHBOARD</span>
                        </div>
                        <div className="col-md-3 my-2 offset-md-2">
                            <div className="row">
                                <div className="fs-6 fw-bold d-flex justify-content-end">
                                    <Form.Label className="me-2 mt-1 text-white">Terminal</Form.Label>
                                    <Form.Select value={selected || ''} inline="true" className="fs-7 terminal-select" size="sm" onChange={(e) => handleChange(e)}>
                                        {/* <option value="ALL">All Terminals</option> */}
                                        {terminals.map((terminal) => (<option key={terminal.terminalCode} value={terminal.terminalCode}>{terminal.terminalCode}</option>))}
                                    </Form.Select>
                                </div>
                            </div>
                        </div>  
                        <div className="col-md-2 my-2 offset-md-1">
                            <div className="row">
                                <div className="fs-6 fw-bold d-flex justify-content-end">
                                    <Form.Label className="me-2 mt-1 text-white">Location</Form.Label>
                                    <Form.Control
                                        className="fs-7"
                                        maxLength={6}
                                        size="sm"
                                        type="text"
                                        name="location"
                                        value={location || ''}
                                        onChange={locationChangeHandler}
                                        onKeyPress={handleKeyPress}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container bg-white">
                    <div className="row">
                        <div className="col-md-3 col-sm-12 my-2 pt-3">
                            <span className="fw-bold">GENSET PTI</span> - {user && user.userName}/{new Date().toDateString()}
                        </div>
                        <div className="col-md-5 col-sm-12 my-2">
                            <Button type="primary" className="rounded" disabled={!location} onClick={getData}>REFRESH</Button>
                            <Link to={{ pathname: "/pti/list", search: `?t=${selected}&l=${location}` }}>
                                <Button type="primary" className="rounded ms-2 mt-2" disabled={!location}>PTI MODE</Button>
                            </Link> 
                            <Link to={{ pathname: "/pti/fuel", search: `?t=${selected}&l=${location}`, /*state: { sel: selected, lc: location }*/ }}>
                                <Button type="primary" className="rounded ms-2 mt-2" disabled={!location}>FUEL MODE</Button>
                            </Link>                            
                        </div>
                        <div className="col-md-1 col-sm-6 my-2 text-md-center">
                        </div>

                        <div className="col-md-3 col-sm-6 my-2 text-md-end">
                            <Button type="primary" className="rounded" disabled={!(selectedRowKeys.length)} onClick={handleApprove}>APPROVE</Button>
                            <Button type="primary" className="rounded ms-2 mt-2">EXIT</Button>
                        </div>
                    </div>
                </div>

            </div>
            <div className="content">
                {
                    !isLoading &&
                    <div className="pt-0 mb-2 table-responsive">
                        <table className="table table-hover align-middle" aria-labelledby="tabelLabel">
                            <thead>
                                <tr className="border border-5 border-top-0 border-secondary bg-light" style={{ height: '40px' }}>
                                    <th className="text-center" width="20">APPROVE</th>
                                    <th className="text-center" width="20">STATUS</th>                                    
                                    <th className="text-center" width="20">LOC</th>
                                    <th className="text-center" width="20">GENSET NO.</th>
                                    <th className="text-center" width="60">SHIP. LINE</th>
                                    <th className="text-center" width="20">MODEL</th>
                                    <th className="text-center" width="20">SERIAL</th>
                                    <th className="text-center text-nowrap" width="20">PUT BACK</th>
                                    <th className="text-center text-nowrap" width="150">PTI TYPE</th>
                                    <th className="text-center" width="20">COMMENTS</th>
                                    <th className="text-center text-nowrap" width="150">PTI DONE</th>
                                    <th className="text-center" width="20">FUEL</th>
                                    <th className="text-center" width="20">GPS HEALTH</th>
                                    <th className="text-center" width="20">INSTAL GPS</th>
                                    <th className="text-center" width="20">CURRENT HOURS</th>
                                    <th className="text-center" width="20">LAST PTI HOURS</th>
                                    <th className="text-center" width="20">LAST PTI DATE</th>                                    
                                    <th className="text-center" width="20">LAST PM HOURS</th>
                                    <th className="text-center" width="20">LAST PM DATE</th>                                    
                                    <th className="text-center" width="20">OIL</th>
                                    <th className="text-center" width="20">PRESTONE</th>
                                    <th className="text-center" width="20">91-7228SUB</th>
                                    <th className="text-center" width="20">B079-718</th>
                                    <th className="text-center" width="20">B079-719</th>
                                    <th className="text-center" width="20">B079-720</th>                                                                       
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.length > 0 ?
                                        data.map((item, index) => {
                                            return (
                                                <tr key={index} className="border border-5 border-secondary bg-white">
                                                    <td className="text-center cell-pad">
                                                            <Checkbox onChange={(e) => handleRowSelect(e, item.equipNum, item.birthDate, item.lifeNum, item.numPhases, item.fuel, item.line, item.ptiDone, item.ptiState)}
                                                            disabled={!(userCanApprove) || ((!(item.ptiState === PTI_STATE_PASSED || item.ptiState === PTI_STATE_FAILED)) && !(item.ptiState == null && item.fuel != null))}>
                                                            {/*(((item.iN_PTI_TYPE === PTI_FUEL || item.iN_PTI_TYPE === SERVICE) && (item.qty <= 0 && item.ptiDone !== NOT_DONE)) || item.qty <= 0) */}
                                                        </Checkbox>
                                                    </td>                                                
                                                    <td className="fs-6 fw-bold text-center cell-pad text-nowrap">{item.eqStatus}</td>                                                    
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.location}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.equipNum}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.line}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.model}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.serial}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad text-nowrap">{putBackItems[item.putBack]}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad text-nowrap">{item.ptiType ? item.ptiType : item.inptiType}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.ptiComment}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad text-nowrap">{item.ptiState}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.fuel}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.inGps === "T" ? "YES" : "NO"}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.inGpsInstl === "T" ? "YES" : "NO"}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.hours}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.lastPtiHours}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{isNaN(Date.parse(item.lastPtiDate)) ? '' : moment(item.lastPtiDate).format(monthFormat)}</td> 
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.lastPmHours}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{isNaN(Date.parse(item.lastPmDate)) ? '' : moment(item.lastPmDate).format(monthFormat)}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.oil}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.prestone}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.p917228Sub}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.b079718}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.b079719}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.b079720}</td>                                                    
                                                </tr>
                                            )
                                        })
                                        :
                                        !onLoad &&
                                        <tr className="border border-5 border-secondary">
                                            <td colSpan={24}>
                                                <span style={{ color: 'red', fontWeight: 'bold' }}>No data found !</span>
                                            </td>
                                        </tr> 
                                }
                        </tbody>
                            <tfoot className="bg-secondary"></tfoot>
                        </table>
                    </div>
                }
                {
                    isLoading &&
                    <div className="border border-5 border-secondary overflow" style={{ borderColor: 'gray', padding: '0px', width: '100%', textAlign: 'center', fontSize: '32px' }}>
                        <i className="fa fa-spinner fa-spin" style={{ paddingTop: '10px' }}></i>
                    </div>
                }

            </div>            
        </>  
    );
};

export default Dashboard