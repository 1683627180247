import { Button } from 'antd';
import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.min.css';
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';
import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";
import _db from "../services/data-service";
import './Dashboard.css';

const TO_BE_PTI = "TO BE PTI";

const PtiList = () => {
    let search = window.location.search; // const search = locat.search;
    let params = new URLSearchParams(search);
    let sTerminal = params.get('t');
    let sLocation = params.get('l');

    const history = useHistory()

    const user = AuthService.getCurrentUser();
    const [onlineUser, setOnlineUser] = useState({ userCode: '', userName: '', password: '', lines: '', class: '', remote: '', terminal: '', allowPti: false });
    const [terminals, setTerminals] = useState([]);
    const [location, setLocation] = useState(sLocation);

    const [isLoading, setIsLoading] = useState(false);
    const [onLoad, setOnLoad] = useState(true);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(sTerminal);

    async function populateTerminals() {
        const { data: response } = await _db.getTerminals();
        setTerminals(response);
    }

    async function getItemsOilPrestone(sContainer) {
        let url = "/api/getItemsOilPrestone";
        const { data: response } = await _db.get(url, { headers: authHeader(), params: { cntrNum: sContainer.trim() } });
        return (response);
    }

    const getUserData = async () => {
        const { data: userData } = await _db.getCurrentUserData();
        setOnlineUser({ ...userData });

        if (sTerminal) {
            setSelected(sTerminal);
        } else
            if (userData.terminal !== '') {
                setSelected(userData.terminal);
            }
    }


    const showPtiForm = async (ilocation, equipNum, line, model, serial, year, putBack, ptiType, hours, 
        comment, eqStatus, damStatus, oosStatus, holdStatus, notes, inPtiType, ptiDone, 
        birthDate, lifeNum, numPhases, inGps, inGpsInstl, lastPtiHours, lastPtiDate, lastPmHours, lastPmDate,
        ptiState, noHoursReason, missingLastInfoReason, ptiNotRequiredReason, hoursType, gpsHealthCheckReason,
        healthCheck, gpsInstalled, mountingBolts, ptiComment) => {

        if (eqStatus !== TO_BE_PTI) return; //On ne traite que les genset qui sont "TO BE PTI"
        
        let localPtiType = (ptiType) ? ptiType : inPtiType;
        if ((!onlineUser.allowPti) && (localPtiType === "")) {
            _db.openNotificationWithIcon('error', 'PTI_TYPE is required, Contact your supervisor!');
        }
        else {
            let itemsOP = await getItemsOilPrestone(equipNum);

            history.push({
                pathname: "/pti/pti", //search: "?id=5",hash: "#react",
                state: {
                    record: {
                        "location": ilocation, equipNum, line, model, serial, year, putBack, ptiType, hours,
                        comment, eqStatus, damStatus, oosStatus, holdStatus, notes, inPtiType, ptiDone,
                        birthDate, lifeNum, numPhases, inGps, inGpsInstl, lastPtiHours, lastPtiDate, lastPmHours, lastPmDate,
                        ptiState, noHoursReason, missingLastInfoReason, ptiNotRequiredReason, hoursType, gpsHealthCheckReason, healthCheck,
                        gpsInstalled, mountingBolts, ptiComment, "sTerminal": selected, "sLocation": location
                    },
                    items: { ...itemsOP }
                },
            });
        }               
    }; 

    //Load on startup
    useEffect(() => {
        populateTerminals();
        getUserData();
    }, []);
   
    useEffect(() => {
        if (sLocation) getData(TO_BE_PTI);
    }, []);

    const getData = async (status) => {
        try {
            setIsLoading(true);
            setOnLoad(false);

            let url = "/api/ptiGetGensets";
            //let URL = `/api/FuelGetGenset?terminal=${terminal}&location=${location}`;
            //console.log('PTI', authHeader());
            const response = await _db.get(url, { headers: authHeader(), params: { terminal: selected, location, status } });

            //console.log('dataLoad', response.data)

            setData(response.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            //console.error(error);
        }
    };

    /* SEARCH */
    const locationChangeHandler = (e) => {
        setLocation(e.target.value.toString().toUpperCase());
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && e.target.value.length > 0) {
            getData(TO_BE_PTI)
        }
    }
    /* END SEARCH */

    return (
        <>
            <div className="border border-5 border-secondary overflow bg-secondary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                <div className="container bg-secondary">
                    <div className="row">
                        <div className="col-md-4 my-2">
                            <span className="fs-4 fw-bold text-white">PTI TRANSACTION</span>
                        </div>
                        <div className="col-md-3 my-2 offset-md-2">
                            <div className="row">
                                <div className="fs-6 fw-bold d-flex justify-content-end">
                                    <Form.Label className="me-2 mt-1 text-white">Terminal</Form.Label>
                                    <Form.Select value={selected || ''} inline="true" className="fs-7 terminal-select" size="sm" onChange={(e) => setSelected(e.target.value)}>
                                        {/* <option value="ALL">All Terminals</option> */}
                                        {terminals.map((terminal) => (<option key={terminal.terminalCode} value={terminal.terminalCode}>{terminal.terminalCode}</option>))}
                                    </Form.Select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 my-2 offset-md-1">
                            <div className="row">
                                <div className="fs-6 fw-bold d-flex justify-content-end">
                                    <Form.Label className="me-2 mt-1 text-white">Location</Form.Label>
                                    <Form.Control
                                        className="fs-7"
                                        maxLength={6}
                                        size="sm"
                                        type="text"
                                        name="location"
                                        value={location || ''}
                                        onChange={locationChangeHandler}
                                        onKeyPress={handleKeyPress}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container bg-white">
                    <div className="row">
                        <div className="col-md-5 col-sm-12 my-2 pt-3">
                            <span className="fw-bold">GENSET PTI</span> - {user && user.userName}/{new Date().toDateString()}.
                            <span className="fw-bold"> TERMINAL : </span> {selected}
                        </div>
                        <div className="col-md-4 col-sm-12 my-2">
                            <Button type="primary" className="rounded" disabled={!location} onClick={() => getData(TO_BE_PTI)}>REFRESH</Button>
                            <Button type="primary" className="rounded ms-2 mt-2" disabled={!location} onClick={() => getData('')}>SHOW ALL STATUS</Button>
                        </div>

                        <div className="col-md-3 col-sm-6 my-2 text-md-end">
                            <Link to={{ pathname: "/pti/dashboard", search: `?t=${selected}&l=${location}`, /*state: { sel: selected, lc: location }*/ }}>
                                <Button type="primary" className="rounded ms-2 mt-2" disabled={!location}>BACK TO DASHBOARD</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                {
                    !isLoading &&
                    <div className="pt-0 mb-2 table-responsive">
                            <table className="table table-hover align-middle" aria-labelledby="tabelLabel">
                            <thead>
                                <tr className="border border-5 border-top-0 border-secondary bg-light" style={{ height: '040px' }}>
                                    <th width="50" className="text-center cell-pad">LOC</th>
                                    <th width="130" className="text-center">GENSET NO.</th>
                                    <th width="100" className="text-center">SHIP.LINE</th>
                                    <th width="50" className="text-center">MODEL</th>
                                    <th width="100" className="text-center">SERIAL</th>
                                    <th width="150" className="text-center">PTI TYPE</th>
                                    <th width="100" className="text-center">GPS HEALTH TO BE DONE</th>
                                    <th width="100" className="text-center">INSTALL GPS TO BE DONE</th>
                                    <th width="50" className="text-center">FUEL</th>
                                    <th width="150" className="text-center">PTI STATE</th>
                                    <th width="100" className="text-center">STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.length > 0 ?
                                        data.map((item) => {
                                            return (
                                                <tr key={item.equipNum}
                                                    className={`border border-5 border-secondary bg-white ${item.eqStatus === TO_BE_PTI && "cursor-pointer"}`}
                                                    onClick={() => showPtiForm(item.location, item.equipNum, item.line, item.model, item.serial, item.year, item.putBack, item.ptiType, 
                                                        item.hours, item.comment, item.eqStatus, item.damStatus, item.oosStatus, item.holdStatus, item.notes, item.inPtiType,
                                                        item.ptiDone, item.birthDate, item.lifeNum, item.numPhases, item.inGps, item.inGpsInstl, item.lastPtiHours, 
                                                        item.lastPtiDate, item.lastPmHours, item.lastPmDate, item.ptiState, item.noHoursReason, item.missingLastInfoReason, 
                                                        item.ptiNotRequiredReason, item.hoursType, item.gpsHealthCheckReason, item.healthCheck, item.gpsInstalled, 
                                                        item.mountingBolts, item.ptiComment)
                                                    }>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.location}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.equipNum}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.line}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.model}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.serial}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad text-nowrap">{item.ptiType ? item.ptiType : item.inPtiType}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.inGps === "T" ? "YES" : "NO"}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.inGpsInstl === "T" ? "YES" : "NO"}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad">{item.fuel}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad text-nowrap">{item.ptiState}</td>
                                                    <td className="fs-6 fw-bold text-center cell-pad text-nowrap">{item.eqStatus}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        !onLoad &&
                                        <tr className="border border-5 border-secondary">
                                            <td colSpan={10}>
                                                <span style={{ color: 'red', fontWeight: 'bold' }}>No data found !</span>
                                            </td>
                                        </tr> 
                                }
                            </tbody>
                            <tfoot className="bg-secondary"></tfoot>
                        </table>
                    </div>
                }
                {
                    isLoading &&
                    <div className="border border-5 border-secondary overflow" style={{ borderColor: 'gray', padding: '0px', width: '100%', textAlign: 'center', fontSize: '32px' }}>
                        <i className="fa fa-spinner fa-spin" style={{ paddingTop: '10px' }}></i>
                    </div>
                }
            </div>          
        </>
    );
};

export default PtiList